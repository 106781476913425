// Copyright 2023 Merit International Inc. All Rights Reserved

import * as AgentsApi from "../gen/agents/apis";
import * as IssuanceApi from "../gen/issuance/apis";
import * as WormholeApi from "../gen/wormhole/apis/";
import * as runtime from "@src/gen/org-portal/runtime";
import {
  Configuration as AgentsConfiguration,
  DefaultConfig as DefaultAgentsConfig,
} from "../gen/agents";
import {
  Configuration,
  DefaultConfig,
  ExtendPolicy201ResponseFromJSON,
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON,
  OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON,
} from "../gen/org-portal";
import { DefaultApi } from "@src/gen/org-portal";
import {
  DefaultConfig as DefaultIssuanceConfig,
  Configuration as IssuanceConfiguration,
} from "../gen/issuance";
import {
  DefaultConfig as DefaultWormholeConfig,
  Configuration as WormholeConfiguration,
} from "../gen/wormhole";
import { useAuthStore } from "@src/stores";
import { useMemo } from "react";
import Constants from "expo-constants";
import type {
  CompoundAtom,
  Conjunction,
  Disjunction,
  ExtendPolicy201Response,
  ExtendPolicyOperationRequest,
  ExtendPolicyRequest,
  ExtendPolicyRequestFormula,
  ResponseContext,
} from "../gen/org-portal";
import type { Configuration as EnvConfig } from "@src/configuration";

const envConfig = Constants.manifest?.extra as EnvConfig;

const BASE_API_CONFIG = {
  basePath: envConfig.api.orgPortal.baseUrl,
  headers: {},
};

type PolicyFieldRule = {
  readonly arguments: readonly string[];
  readonly errorMessage: string;
  readonly predicate: string;
  readonly target?: string;
};

/*
  These functions, and ExtendPolicyAPI, which calls them,
  are required because our typescript swagger code generator does not support discriminated union types.
  What this does is override the api for ExtendPolicy and handles recursively nested formulas.
 */

// eslint-disable-next-line
const extendPolicyRequestTaggedUnionFormulaToJSON = (
  value?: ExtendPolicyRequestFormula | null
): object | null | undefined => {
  if (value === undefined) {
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (value === null) {
    return null;
  }
  switch (value.formulaType) {
    case "Disjunction":
      return {
        disjunction: (value as Disjunction).disjunction.map(a =>
          extendPolicyRequestTaggedUnionFormulaToJSON(a)
        ),
        formulaType: "Disjunction",
      };
    case "Conjunction":
      return {
        conjunction: (value as Conjunction).conjunction.map(a =>
          extendPolicyRequestTaggedUnionFormulaToJSON(a)
        ),
        formulaType: "Conjunction",
      };
    case "CompoundAtom":
      const ca = value as CompoundAtom;

      return {
        arguments: ca.arguments,
        errorMessage: ca.errorMessage,
        formula: extendPolicyRequestTaggedUnionFormulaToJSON(ca.formula),
        formulaType: "CompoundAtom",
        predicate: ca.predicate,
        target: ca.target,
      };
    default:
      const fr = value as unknown as PolicyFieldRule;

      return {
        arguments: fr.arguments,
        errorMessage: fr.errorMessage,
        formulaType: "CompoundAtom",
        predicate: fr.predicate,
        target: fr.target,
      };
  }
};

// eslint-disable-next-line
const extendPolicyRequestToJSON = (value: ExtendPolicyRequest): any => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (value === undefined) {
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (value === null) {
    return null;
  }
  // eslint-disable-next-line
  const permissions =
    value.permissions === undefined
      ? undefined
      : value.permissions.map(
          GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON
        );

  return {
    description: value.description,
    falseMessage: value.falseMessage,
    formula: extendPolicyRequestTaggedUnionFormulaToJSON(value.formula),
    name: value.name,
    permissions,
    // eslint-disable-next-line
    rule: (value.rule as readonly any[]).map(
      OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON
    ),
    sourcePolicyID: value.sourcePolicyID,
    trueMessage: value.trueMessage,
  };
};

// eslint-disable-next-line functional/no-class
class ExtendPolicyApi extends DefaultApi {
  public async extendPolicyRaw(
    requestParameters: ExtendPolicyOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ExtendPolicy201Response>> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
      throw new runtime.RequiredError(
        "orgID",
        "Required parameter requestParameters.orgID was null or undefined when calling extendPolicy."
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (requestParameters.properties === null || requestParameters.properties === undefined) {
      throw new runtime.RequiredError(
        "properties",
        "Required parameter requestParameters.properties was null or undefined when calling extendPolicy."
      );
    }

    // eslint-disable-next-line
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    // eslint-disable-next-line functional/immutable-data
    headerParameters["Content-Type"] = "application/json";

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
      // eslint-disable-next-line functional/immutable-data
      headerParameters.Authorization = String(requestParameters.authorization);
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
      // eslint-disable-next-line functional/immutable-data
      headerParameters["X-Session-Store"] = String(requestParameters.xSessionStore);
    }

    // eslint-disable-next-line functional/no-this-expression
    const response = await this.request(
      {
        body: extendPolicyRequestToJSON(requestParameters.properties),
        headers: headerParameters,
        method: "POST",
        path: `/orgs/{orgID}/policies`.replace(
          `{${"orgID"}}`,
          encodeURIComponent(String(requestParameters.orgID))
        ),
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue =>
      ExtendPolicy201ResponseFromJSON(jsonValue)
    );
  }

  public async extendPolicy(
    requestParameters: ExtendPolicyOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ExtendPolicy201Response> {
    // eslint-disable-next-line functional/no-this-expression
    const response = await this.extendPolicyRaw(requestParameters, initOverrides);

    return response.value();
  }
}

export const useApi = () => {
  const { accessToken, clear: clearAuthStore, session, setSession } = useAuthStore();

  return useMemo(() => {
    const checkForUnauthorized = async (context: ResponseContext): Promise<Response> => {
      const p = await new Promise<Response>((resolve, reject) => {
        if (context.response.status === 401) {
          clearAuthStore();
          reject(Error("401 Unauthorized"));
        } else {
          resolve(context.response);
        }
      });

      return p;
    };

    // Disabled temporarily
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const useFreshToken = async (context: ResponseContext): Promise<Response> => {
      const p = await new Promise<Response>(resolve => {
        if (context.response.headers.has("X-Session-Store")) {
          const newSession = context.response.headers.get("X-Session-Store");
          setSession(newSession);
        }

        resolve(context.response);
      });

      return p;
    };

    const getHeaders = () => {
      if (accessToken !== null && session !== null) {
        return {
          ...BASE_API_CONFIG.headers,
          Authorization: `Bearer ${accessToken}`,
          "X-Session-Store": session,
        };
      }
      if (accessToken !== null) {
        return {
          ...BASE_API_CONFIG.headers,
          Authorization: `Bearer ${accessToken}`,
        };
      }

      return BASE_API_CONFIG.headers;
    };

    const notAuthorized = accessToken === null;
    const headers = getHeaders();
    const makeConfig = <T>(defaultConfig: T, url?: string): T => ({
      ...defaultConfig,
      basePath: url ?? BASE_API_CONFIG.basePath,
      headers,
      middleware: [notAuthorized ? {} : { post: checkForUnauthorized }],
    });

    const config = makeConfig(DefaultConfig);

    const api = new DefaultApi(new Configuration(config));

    const extendPolicyApi = new ExtendPolicyApi(new Configuration(config));

    const issuanceApis = {
      containerTemplates: new IssuanceApi.ContainerTemplatesApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      containers: new IssuanceApi.ContainersApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      dataMapping: new IssuanceApi.DataMappingApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      entityMerges: new IssuanceApi.EntityMergesApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      fieldKinds: new IssuanceApi.FieldKindsApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      infrastructure: new IssuanceApi.InfrastructureApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      mergeRequests: new IssuanceApi.MergeRequestsApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      policies: new IssuanceApi.PoliciesApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      policyRequests: new IssuanceApi.PolicyRequestsApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      search: new IssuanceApi.SearchApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      signedEntity: new IssuanceApi.SignedEntityApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      templateFields: new IssuanceApi.TemplateFieldsApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
    };

    const agentsApi = {
      agentEntityLinks: new AgentsApi.AgentEntityLinksApi(
        new AgentsConfiguration(makeConfig(DefaultAgentsConfig, envConfig.api.agents.baseUrl))
      ),
      agentVersions: new AgentsApi.AgentVersionsApi(
        new AgentsConfiguration(makeConfig(DefaultAgentsConfig, envConfig.api.agents.baseUrl))
      ),
      agents: new AgentsApi.AgentsApi(
        new AgentsConfiguration(makeConfig(DefaultAgentsConfig, envConfig.api.agents.baseUrl))
      ),
      infrastructure: new AgentsApi.InfrastructureApi(
        new AgentsConfiguration(makeConfig(DefaultAgentsConfig, envConfig.api.agents.baseUrl))
      ),
    };

    const wormholeApi = {
      dataSources: new WormholeApi.DataSourcesApi(
        new WormholeConfiguration(makeConfig(DefaultWormholeConfig, envConfig.api.wormhole.baseUrl))
      ),
      files: new WormholeApi.FilesApi(
        new WormholeConfiguration(makeConfig(DefaultWormholeConfig, envConfig.api.wormhole.baseUrl))
      ),
      infrastructure: new WormholeApi.InfrastructureApi(
        new WormholeConfiguration(makeConfig(DefaultWormholeConfig, envConfig.api.wormhole.baseUrl))
      ),
      integrations: new WormholeApi.IntegrationsApi(
        new WormholeConfiguration(makeConfig(DefaultWormholeConfig, envConfig.api.wormhole.baseUrl))
      ),
      records: new WormholeApi.RecordsApi(
        new WormholeConfiguration(makeConfig(DefaultWormholeConfig, envConfig.api.wormhole.baseUrl))
      ),
    };

    return { agentsApi, api, config, extendPolicyApi, issuanceApis, wormholeApi };
  }, [accessToken, clearAuthStore, setSession, session]);
};
