// Copyright 2024 Merit International Inc. All Rights Reserved

import { InvalidPolicyError } from "@src/screens/Policies/utils";
import { v4 as uuidv4 } from "uuid";
import type { FieldRule, TemplateRule } from "./types";

export const newFieldRule = (): FieldRule => ({
  id: uuidv4(),
});

export const newTemplateRule = (): TemplateRule => ({
  fieldCombinationType: "all",
  fieldRules: [],
  fieldValuesType: "any",
  id: uuidv4(),
  templateId: null,
  templateStatus: "Active",
});

export const SplitTime = (arg: string): readonly string[] => {
  const splitHMS = arg.split(":");
  if (splitHMS.length !== 3) {
    throw new InvalidPolicyError("Wanted HH:MM:SS format for time field");
  }

  return splitHMS;
};
