// Copyright 2024 Merit International Inc. All Rights Reserved

/*
 Utilities for converting between platform policy data structures and our policy data structure
*/

import {
  ExtendPolicyRequestFormulaFormulaTypeEnum,
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedActionEnum,
} from "@src/gen/org-portal";
import { Helpers } from "@merit/frontend-utils";
import {
  PredicateNeedsThreeValuesMap,
  PredicateNeedsValueSet,
} from "@src/screens/CreatePolicy/constants";
import type {
  ExtendPolicyRequest,
  GetPolicies200ResponsePoliciesInnerResponseFormulasOwn,
} from "@src/gen/org-portal";
import type {
  FieldLevelPredicate,
  FieldRule,
  PolicyValues,
  TemplateRule,
} from "@src/screens/CreatePolicy/types";

const policyPermissionsToPlatformPermissions = (
  policyPerms: PolicyValues["policyPermissions"]
): ExtendPolicyRequest["permissions"] => {
  const readAction =
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedActionEnum.Read;
  const extendAction =
    GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerPermittedActionEnum.Extend;
  const othersView = policyPerms.othersView === "yes";
  const othersUse = policyPerms.othersUse === "yes";

  return [
    {
      action: readAction,
      permissibleToPermit: { action: readAction, grantedToName: "None" },
      permitted: { action: readAction, grantedToName: othersView ? "All" : "None" },
    },
    {
      action: extendAction,
      permissibleToPermit: { action: extendAction, grantedToName: "None" },
      permitted: { action: extendAction, grantedToName: othersUse ? "All" : "None" },
    },
  ];
};

// eslint-disable-next-line functional/no-class
export class InvalidPolicyError extends Error {}
// eslint-disable-next-line functional/no-class
export class PolicyParsingError extends Error {}

const { None } = Helpers;

const ParseArguments = (
  predicate: FieldLevelPredicate,
  value: string | undefined
): readonly string[] => {
  if (PredicateNeedsThreeValuesMap.has(predicate)) {
    if (None(value)) {
      throw new InvalidPolicyError(`Invalid arguments list for predicate: ${predicate}`);
    }
    const func = PredicateNeedsThreeValuesMap.get(predicate);
    if (None(func)) {
      throw new PolicyParsingError(`Unexpected predicate: ${predicate}`);
    }

    return func(value);
  }

  if (PredicateNeedsValueSet.has(predicate)) {
    if (None(value)) {
      throw new InvalidPolicyError(`Invalid argument for predicate: ${predicate}`);
    }

    return [value];
  }

  if (None(value)) {
    return [];
  }

  return [value];
};

const fieldRuleToCompoundAtom = (
  rule: FieldRule
): GetPolicies200ResponsePoliciesInnerResponseFormulasOwn => {
  if (None(rule.predicate)) {
    throw new InvalidPolicyError("predicate must be defined.");
  }

  return {
    arguments: ParseArguments(rule.predicate, rule.value),
    errorMessage: "error matching field",
    formulaType: "CompoundAtom",
    predicate: rule.predicate,
    target: rule.templateFieldId,
  } as GetPolicies200ResponsePoliciesInnerResponseFormulasOwn;
};

const templateRuleToTemplateFormula = (
  rule: TemplateRule
): GetPolicies200ResponsePoliciesInnerResponseFormulasOwn => {
  const statusArgsMap = {
    Active: ["1", rule.templateId, "active"],
    Inactive: ["1", rule.templateId, "inactive"],
    any: ["1", rule.templateId, "both"],
  };
  const outer = {
    arguments: statusArgsMap[rule.templateStatus],
    errorMessage: "Error matching template",
    formulaType: "CompoundAtom",
    predicate: "ReceivedXContainersFromTemplates",
  } as GetPolicies200ResponsePoliciesInnerResponseFormulasOwn;
  if (rule.fieldRules.length === 0) {
    return outer;
  }
  // we know that there's at least one field rule
  // so we have to wrap with a compound atom with the ReceivedXContainersFromTemplates predicate

  if (rule.fieldRules.length > 1) {
    if (rule.fieldCombinationType === "any") {
      return {
        formula: {
          disjunction: rule.fieldRules.map(fieldRule => fieldRuleToCompoundAtom(fieldRule)),
          formulaType: "Disjunction",
        },
        ...outer,
      } as GetPolicies200ResponsePoliciesInnerResponseFormulasOwn;
    }

    return {
      formula: {
        conjunction: rule.fieldRules.map(fieldRule => fieldRuleToCompoundAtom(fieldRule)),
        formulaType: "Conjunction",
      },
      ...outer,
    } as GetPolicies200ResponsePoliciesInnerResponseFormulasOwn;
  }

  if (None(rule.fieldRules[0].predicate)) {
    throw new InvalidPolicyError("predicate must be defined");
  }

  return {
    formula: {
      arguments: ParseArguments(rule.fieldRules[0].predicate, rule.fieldRules[0].value),
      errorMessage: "error matching field",
      predicate: rule.fieldRules[0].predicate,
      target: rule.fieldRules[0].templateFieldId,
    },
    ...outer,
  } as GetPolicies200ResponsePoliciesInnerResponseFormulasOwn;
};

export const policyToPlatformPolicy = (
  policy: PolicyValues,
  basePolicyUUID: string
): ExtendPolicyRequest => {
  const nestedFormula = policy.templateRules.map(rule => templateRuleToTemplateFormula(rule));
  const formula =
    policy.ruleCombinationType === "any"
      ? {
          disjunction: nestedFormula,
          formulaType: ExtendPolicyRequestFormulaFormulaTypeEnum.Conjunction,
        }
      : {
          conjunction: nestedFormula,
          formulaType: ExtendPolicyRequestFormulaFormulaTypeEnum.Conjunction,
        };

  return {
    description: policy.policyDescription,
    falseMessage: "You did not pass this policy, contact your administrator.",
    formula,
    name: policy.policyName,
    permissions: policyPermissionsToPlatformPermissions(policy.policyPermissions),
    rule: [],
    sourcePolicyID: basePolicyUUID,
    trueMessage: "You passed this policy.",
  };
};
