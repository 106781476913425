// Copyright 2024 Merit International Inc. All Rights Reserved

import * as Yup from "yup";
import { CreatePolicyForm } from "./CreatePolicyForm";
import { Formik } from "formik";
import {
  InvalidPolicyError,
  PolicyParsingError,
  policyToPlatformPolicy,
} from "@src/screens/Policies/utils";
import { useAlertStore } from "@src/stores";
import { useApi } from "@src/api/api";
import { useLoadedConfigurationState } from "@src/hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import { useNavigation } from "@react-navigation/native";
import type { ExtendPolicyOperationRequest } from "@src/gen/org-portal";
import type { FC } from "react";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { PolicyValues } from "../types";
import type { RouteParams } from "@src/Router";

const fieldRuleSchema = Yup.object({
  id: Yup.string().uuid().required(),
  predicate: Yup.string().required("Predicate must be specified"),
  templateFieldId: Yup.string()
    .required("Template field must be selected")
    .uuid("Template field must be selected"),
  value: Yup.string(),
});

const templateRuleSchema = Yup.object({
  fieldCombinationType: Yup.string().oneOf(["all", "any"] as const),
  fieldRules: Yup.array().of(fieldRuleSchema),
  fieldValuesType: Yup.string().oneOf(["any", "specific"] as const),
  id: Yup.string().uuid().required(),
  templateId: Yup.string()
    .required("Template must be selected")
    .uuid("Template must be selected")
    .nullable(),
  templateStatus: Yup.string().oneOf(["any", "Active", "Inactive"] as const),
});

const formSchema = Yup.object({
  policyDescription: Yup.string()
    .optional()
    .max(300, "Policy description must be 300 characters or less"),
  policyName: Yup.string().min(3, "Policy name must be at least 3 characters").required(),
  policyPermissions: Yup.object().shape({
    othersUse: Yup.string().oneOf(["yes", "no"] as const),
    othersView: Yup.string().oneOf(["yes", "no"] as const),
  }),
  ruleCombinationType: Yup.string().oneOf(["all", "any"] as const),
  templateRules: Yup.array().min(1, "At least one rule expected").of(templateRuleSchema),
});

const initialValues: PolicyValues = {
  policyName: "",
  policyPermissions: {
    othersUse: "yes",
    othersView: "yes",
  },
  ruleCombinationType: "all",
  templateRules: [],
};

export const CreatePolicyFormikWrapper: FC = () => {
  const config = useLoadedConfigurationState();
  const { selectedOrgId } = useLoggedInAuthState();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const { extendPolicyApi } = useApi();
  const { deleteAlert, setAlert } = useAlertStore();

  const showAlert = (errorID: string, errorMessage: string) => {
    setAlert({
      closable: true,
      id: errorID,
      onPressDelete: id => {
        deleteAlert(id);
      },
      text: `Invalid policy: ${String(errorMessage)}`,
      type: "error",
    });
  };

  return (
    <Formik<PolicyValues>
      initialValues={initialValues}
      onReset={(_, helpers) => {
        helpers.resetForm({ values: initialValues });
      }}
      onSubmit={values => {
        const submitPolicy = async () => {
          try {
            const platformPolicy = policyToPlatformPolicy(
              values,
              config.configuration.basePolicyUUID
            );
            const extendPolicyParams: ExtendPolicyOperationRequest = {
              orgID: selectedOrgId,
              properties: platformPolicy,
            };

            await extendPolicyApi.extendPolicy(extendPolicyParams);
            navigation.navigate("Policies");
          } catch (err) {
            if (err instanceof InvalidPolicyError) {
              showAlert("invalid-policy-error", `Invalid policy: ${String(err)}`);
            } else if (err instanceof PolicyParsingError) {
              showAlert("policy-validation-error", `Error validating policy: ${String(err)}`);
            } else {
              setAlert({
                closable: true,
                id: "policy-submit-error",
                onPressDelete: id => {
                  deleteAlert(id);
                },
                text: `Error occurred saving policy: ${String(err)}`,
                type: "error",
              });
            }
          }
        };
        submitPolicy();
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      validationSchema={formSchema}
    >
      <CreatePolicyForm />
    </Formik>
  );
};
